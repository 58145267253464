import React from "react";
import { RichText } from "prismic-reactjs";
//import { withPreview } from "gatsby-source-prismic-graphql";
import moment from "moment";
import { linkResolver } from "@utilities/link-resolver.js";
import get from "lodash/get";
import { graphql, Link } from "gatsby";
import Layout from "@components/layout";
import slugify from "slugify";

import "./../scss/blog-list.scss";

export const pageQuery = graphql`
  query blogPageQuery {
    allPrismicBlog(sort: { fields: data___date, order: DESC }) {
      edges {
        node {
          data {
            headline
            byline
            content {
              text
              html
              raw
            }
            excerpt {
              text
              html
              raw
            }
            date
          }
          uid
          tags
        }
      }
    }
  }
`;

const Blog = (props) => {
  const data = props.data;

  const blogEntries = get(data, "allPrismicBlog.edges");
  return (
    <Layout>
      <div className="blog-list">
        <h2>Blog</h2>
        <div className="blog-list--filter" />
        {blogEntries &&
          blogEntries.map((entry, index) => {
            const item = entry.node;
            const date = moment(item.data.date);
            const tags = get(item, "tags", []);
            return (
              <article key={index}>
                <h3>
                  <Link to={`/blog/${item.uid}`}>{item.data.headline}</Link>
                </h3>
                {item.data.byline && <div>By: {item.data.byline}</div>}
                <time>{date.format("dddd, MMMM Do YYYY")}</time>
                {RichText.render(item.data.excerpt.raw, linkResolver)}
                <div className="cta">
                  <Link to={`/blog/${item.uid}/`}>Read Now</Link>
                </div>
                {tags.length > 0 && (
                  <div className="tags">
                    <span className="tags--label">Tags:</span>
                    {tags.map((tag, index) => {
                      const slug = slugify(tag.toLowerCase());
                      return (
                        <Link to={`/blog/tag/${slug}/`}>
                          <span className="tags--item" key={index}>
                            {tag}
                          </span>
                        </Link>
                      );
                    })}
                  </div>
                )}
              </article>
            );
          })}
      </div>
    </Layout>
  );
};

export default Blog;
